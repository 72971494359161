import React from 'react';
import { graphql } from 'gatsby';
import projectStyle from './projects.module.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import FontAwesome from 'react-fontawesome';
import 'font-awesome/css/font-awesome.css';

export const query = graphql`
	query($slug: String!) {
		contentfulProjects(slug: { eq: $slug }) {
			title
			projectImage {
				resize(width: 1920) {
					src
				}
			}
			publishedDate(formatString: "MMMM Do, YYYY")
			body {
				json
			}
			githubUrl
			liveUrl
		}
	}
`;

const Project = (props) => {
	const options = {
		renderNode: {
			'embedded-asset-block': (node) => {
				const alt = node.data.target.fields.title['en-US'];
				const url = node.data.target.fields.file['en-US'].url;
				return <img src={url} alt={alt} />;
			}
		}
	};
	const style = { backgroundImage: 'url(' + props.data.contentfulProjects.projectImage.resize.src + ')' };
	return (
		<div className={projectStyle.container}>
			<div className={projectStyle.img} style={style}>
				<h1>{props.data.contentfulProjects.title}</h1>
			</div>
			<div className={projectStyle.body}>
				<p>{documentToReactComponents(props.data.contentfulProjects.body.json, options)}</p>
			</div>
			<div className={projectStyle.links}>
				{props.data.contentfulProjects.githubUrl && (
					<a href={props.data.contentfulProjects.githubUrl} target="blank">
						<FontAwesome className={projectStyle.icon} name="github" />View on github
					</a>
				)}
				{props.data.contentfulProjects.liveUrl && (
					<a href={props.data.contentfulProjects.liveUrl} target="blank">
						<FontAwesome className={projectStyle.icon} name="globe" />See Live Site
					</a>
				)}
			</div>
		</div>
	);
};

export default Project;
